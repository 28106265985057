// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-gatsby-mdx-mdx-wrappers-dir-8-df-3-af-7-a-004-c-6216-f-3-d-03-f-20-e-0339-bdb-scope-hash-8-d-5431266-fc-203281-a-7-d-4-aa-34-adf-49-a-7-js": () => import("/Users/marcelmeyer/Develop/devdude/leavingtraces/.cache/gatsby-mdx/mdx-wrappers-dir/8df3af7a004c6216f3d03f20e0339bdb--scope-hash-8d5431266fc203281a7d4aa34adf49a7.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-8-df-3-af-7-a-004-c-6216-f-3-d-03-f-20-e-0339-bdb-scope-hash-8-d-5431266-fc-203281-a-7-d-4-aa-34-adf-49-a-7-js" */),
  "component---src-templates-destination-js": () => import("/Users/marcelmeyer/Develop/devdude/leavingtraces/src/templates/Destination.js" /* webpackChunkName: "component---src-templates-destination-js" */),
  "component---src-pages-404-js": () => import("/Users/marcelmeyer/Develop/devdude/leavingtraces/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-destinations-js": () => import("/Users/marcelmeyer/Develop/devdude/leavingtraces/src/pages/destinations.js" /* webpackChunkName: "component---src-pages-destinations-js" */),
  "component---src-pages-imprint-js": () => import("/Users/marcelmeyer/Develop/devdude/leavingtraces/src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("/Users/marcelmeyer/Develop/devdude/leavingtraces/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/Users/marcelmeyer/Develop/devdude/leavingtraces/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

exports.data = () => import("/Users/marcelmeyer/Develop/devdude/leavingtraces/.cache/data.json")

